import React from "react";
import {makeStyles} from "@mui/styles";
import {Grid, Typography} from "@mui/material";
import AnimateContainer from "./AnimateContainer";
import Background from './assets/Background.jpg';

const useStyles = makeStyles({
    root: {
        minHeight: '100vh',
        minWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url(" + Background + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    mainSection: {
        marginBottom: '0vh !important',
        position: 'relative',
        "@media (max-width: 680px)": {
            marginBottom: '2vh !important',
        }
    },
    content: {
        padding: '5vh 7vw',
        "@media (max-width: 680px)": {
            padding: '5vh 10vw',
        },
    },
    title: {
        textAlign: 'center',
        fontSize: '4.2vh !important',
        fontWeight: 'bolder !important',
        lineHeight: '1 !important',
        fontFamily: 'Century Gothic !important',
        "@media (max-width: 680px)": {
            fontSize: '2.4vh !important',
        },
        color: '#000000'
    },
    laurels: {
        textAlign: 'center',
        fontSize: '35vh !important',
        fontWeight: 'bolder !important',
        fontFamily: 'Sen ExtraBold !important',
        lineHeight: '0.9 !important',
        position: 'absolute',
        "@media (max-width: 680px)": {
            fontSize: '18vw !important',
        },
        transition: 'font-size 1s ease',
        '&:hover': {
            fontSize: '38vh !important',
        }
    },
    sectionTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        // marginTop: '5vh !important'
    },
    sectionTitleText: {
        fontSize: '2.2vh !important',
        fontWeight: 'lighter !important',
        lineHeight: '1 !important',
        fontFamily: 'Century Gothic !important',
        "@media (max-width: 680px)": {
            fontWeight: 'normal !important',
        }
    },
    contactSection: {
        marginTop: '4vh !important',
        "@media (max-width: 680px)": {
            marginTop: '4vh !important'
        }
    }
});

export default function ComingSoon() {
    const classes = useStyles();

    return (
        <div className={classes.root} id={'projects'}>
            <div className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.mainSection}>
                        <AnimateContainer type={'animate__fadeInUp'}>
                            <Typography variant={'h4'} className={classes.title}>
                                We are working on different exciting projects and also on developing our website.
                                Now that you have dropped by, we want you to know that we work not only on creating
                                new brands but also on transforming the existing ones so that your brands excel in impacting
                                the lives of the people they serve.
                            </Typography>
                        </AnimateContainer>
                    </Grid>
                    <Grid item xs={12} className={classes.mainSection} id={'laurelsSection'}>
                        {/*<AnimateContainer type={'animate__zoomIn'}>*/}
                            {/*<video className="bg-video" autoPlay muted loop style={{width: '80vw'}}>*/}
                            {/*    <source src={Video} type="video/mp4"/>*/}
                            {/*</video>*/}
                            <Typography variant={'h1'} className={classes.laurels} id={'videoText'}>
                            {/*<Typography variant={'h1'} className={classes.laurels}>*/}
                                LAURELS
                            </Typography>
                        {/*</AnimateContainer>*/}
                    </Grid>
                    <Grid item xs={12} className={classes.sectionTitle}>
                        <AnimateContainer type={'animate__fadeIn'}>
                            <Typography variant={'caption'} className={classes.sectionTitleText}>
                                Our Key Capabilities
                            </Typography>
                        </AnimateContainer>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AnimateContainer type={'animate__fadeInDown'}>
                            <Typography variant={'body1'} className={classes.title}>
                                Strategy
                            </Typography>
                        </AnimateContainer>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AnimateContainer type={'animate__fadeInDown'}>
                            <Typography variant={'body1'} className={classes.title}>
                                Identity
                            </Typography>
                        </AnimateContainer>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AnimateContainer type={'animate__fadeInDown'}>
                            <Typography variant={'body1'} className={classes.title}>
                                Experience
                            </Typography>
                        </AnimateContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.contactSection}>
                        <div className={classes.sectionTitle}>
                            <Typography variant={'caption'} className={classes.sectionTitleText}>
                                Say Hello to Us
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{width: '100%', textAlign: 'center'}}>
                        <a className={classes.title} id={'email'}
                           href={'mailto:hello@laurels.agency'}>
                            hello@laurels.agency
                        </a>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}